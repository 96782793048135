import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import richText from "../serializers/richText"

import Layout from "../components/Layout"
import SEO from "../components/seo"

export default () => {
  const data = useStaticQuery(graphql`
    {
      allSanityInformation {
        nodes {
          title
          seo {
            metaTitle
            metaDescription
            openGraphImage {
              asset {
                url
              }
            }
            openGraphTitle
            openGraphDescription
            twitterTitle
            twitterDescription
            twitterImage {
              asset {
                url
              }
            }
          }
          image {
            asset {
              url
            }
          }
          bio: _rawBio
          email
          awards: awardsList {
            _key
            year
            award
          }
          education: educationlist {
            _key
            year
            focus
            school
          }
          experience {
            _key
            focus
            name
          }
          teaching: teachingList {
            _key
            year
            focus
            school
          }
        }
      }
    }
  `)

  const {
    image,
    bio,
    email,
    awards = [],
    education = [],
    teaching = [],
    experience = [],
    seo,
  } = data.allSanityInformation.nodes[0]

  return (
    <Layout>
      <SEO title="Home" />
      <section className="bio grid-container contained pt0 pt2--md">
        <section className="row bio__hero">
          <aside className="col c4--md c4--lg">
            <picture>
              <source
                srcSet={`${image.asset.url}?w=1000&auto=format&q=75`}
                media="(min-width: 600px)"
              />
              <img
                alt="Peter Coy Playwright"
                className="db"
                src={`${image.asset.url}?w=600&auto=format`}
              />
            </picture>
            <section className="pt2 show--md ">
              <p className="serif--bold serif--sm serif--md--md">Contact</p>
              {email && (
                <>
                  <a
                    href={`mailto:${email}`}
                    className="link--underline link db serif--sm serif--md--md"
                  >
                    {email}
                  </a>

                  <p className="serif--sm serif--md--md pt1">
                    For press inquiries, please contact{" "}
                    <a
                      className="link--underline"
                      href="mailto:jacqueline@olucompany.com"
                    >
                      jacqueline@olucompany.com
                    </a>
                  </p>
                </>
              )}
            </section>
          </aside>
          <aside className="col show--lg c1--lg c2--xl" />
          <aside className="col c8--md c7--lg c6--xl pt5 pt0--md">
            <div className="rich-text serif--sm serif--md--md">
              <BlockContent blocks={bio} serializers={richText} />
            </div>
            <nav className="hide--md bio__contact">
              <p className="serif--bold serif--sm serif--md--md">Contact</p>
              {email && (
                <a
                  href={`mailto:${email}`}
                  className="link--underline link db serif--sm serif--md--md"
                >
                  {email}
                </a>
              )}
            </nav>
          </aside>
        </section>
        <section className="bio__acheivements row pt5 pt10--lg">
          <aside className="bio__acheivements--awards col c6--lg">
            <p className="serif--bold serif--lg--md">Awards</p>
            {awards.map((award, index) => {
              return (
                <aside
                  className="row award pt1 serif--sm serif--md--md"
                  key={award._key}
                >
                  <span className="col c4 c1--md c3--lg">
                    <p className="serif--sm serif--md--md">{award.year}</p>
                  </span>
                  <span className="col c9 c7--md c6--lg">
                    <p className="serif--sm serif--md--md">{award.award}</p>
                  </span>
                </aside>
              )
            })}
          </aside>
          <aside className="bio__acheivements--teaching col c6--lg pt1 pt0--md">
            <p className="serif--bold serif--md serif--lg--md">Experience</p>
            {experience.map((exp, index) => {
              return (
                <aside
                  className="row award pt1 serif--sm serif--md--md"
                  key={exp._key}
                >
                  <span className="col c4 c1--md c3--lg ">
                    <p className="serif--sm serif--md--md">{exp.focus}</p>
                  </span>
                  <span className="col c4 c4--md c5--lg pr3--md">
                    <p className=" serif--sm serif--md--md">{exp.name}</p>
                  </span>
                </aside>
              )
            })}
          </aside>
          <aside className="bio__acheivements--education col c6--lg pt1 pt2--md">
            <p className="serif--bold serif--md serif--lg--md">Education</p>
            {education.map((education, index) => {
              return (
                <aside
                  className="row award pt1 serif--sm serif--md--md"
                  key={education._key}
                >
                  <span className="col c4 c4--md c5--lg pr3--md">
                    <p className=" serif--sm serif--md--md">
                      {education.focus}
                    </p>
                  </span>
                  <span className="col c4 c3--md c4--lg">
                    <p className=" serif--sm serif--md--md pr1 pr2--md">
                      {education.school}
                    </p>
                  </span>
                </aside>
              )
            })}
          </aside>
        </section>
      </section>
    </Layout>
  )
}
